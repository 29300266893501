import { mkenum } from "../utils/mkenum";

export const ProductConfigType = mkenum({
  preset: "preset",
  custom: "custom",
  usage: "usage",
  offer: "offer",
  "flat-rate": "flat-rate",
});

export type ProductConfigType =
  (typeof ProductConfigType)[keyof typeof ProductConfigType];

export const productConfigTypes = Object.values(
  ProductConfigType,
) as ProductConfigType[];
