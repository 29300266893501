import type { CertificateTheme } from "@s2z-platform/constants";
import type { YYYYMMDateString, YYYYMMDDDateString } from "../../common";

export class YYYYMMDDDateRange {
  start: YYYYMMDDDateString;
  end: YYYYMMDDDateString;
}

export class CustomerFootprintConfig {
  offsetFootprint: boolean;
  period: YYYYMMDDDateRange;
}

export class CustomerCertificateConfig {
  hideCost: boolean;
  // TODO: make themes type, and import to front (1 source of truth)
  theme: CertificateTheme = "standard";
  reference?: string;
}

export class CustomerIdentifierChoiceJSON {
  identifier: string;
  quantity: number;
}

class SharedCustomerConfigJSON {
  footprintConfig?: CustomerFootprintConfig;
  certificateConfig?: CustomerCertificateConfig;
}

export class MileageDetailsJSON {
  years: number;
  effectiveStartDate: YYYYMMDateString;
}

// BANANA - Move offset settings (and store info?) into customer config entity
export class EcommerceIntegrationCustomerConfigJSON {
  // not present on older subscriptions which attached the offset settings to the store
  // thanks CW
  offsetSettingsId?: number;
}

export class PresetCustomerConfigJSON extends SharedCustomerConfigJSON {
  plansConfig: CustomerIdentifierChoiceJSON[];
}
export class CustomCustomerConfigJSON extends SharedCustomerConfigJSON {
  optionsConfig: CustomerIdentifierChoiceJSON[];
}
export class UsageCustomerConfigJSON extends SharedCustomerConfigJSON {
  usageConfig: EcommerceIntegrationCustomerConfigJSON;
}
export class CarbonVehicleCustomerConfigJSON extends SharedCustomerConfigJSON {
  vehicleUuids: string[];
  mileageDetails: MileageDetailsJSON;
}
export class OfferCustomerConfigJSON extends CustomCustomerConfigJSON {
  offerConfig: { eventName: string; offerCode?: string };
}

export class FlatRateCustomerConfigJSON extends SharedCustomerConfigJSON {}

export type CustomerConfigJSON =
  | PresetCustomerConfigJSON
  | CustomCustomerConfigJSON
  | UsageCustomerConfigJSON
  | CarbonVehicleCustomerConfigJSON
  | OfferCustomerConfigJSON
  | FlatRateCustomerConfigJSON;
