/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/function-component-definition */
import { Accordion, Modal, Spinner, Stack, Tab, Tabs } from "react-bootstrap";
import { skipToken } from "@reduxjs/toolkit/query";
import { workingToDisplayUnits } from "@s2z-platform/functions";
import { FormattedDate, FormattedNumber } from "react-intl";
import { Fragment, useMemo } from "react";
import { EvidenceFeatureEnum } from "@s2z-platform/constants";
import Sqids from "sqids";
import {
  useGetSqidAlphabetQuery,
  useGetUserInfoQuery,
  useLazyGetEvidenceEntrySignedUrlQuery,
  useUpdateEstimateStatusMutation,
} from "../../services/userApi";
import { calculatedFootprintCopy } from "./calculatedFootprintCopy";

type UserModalProps = {
  uuid: string | undefined;
  show: boolean;
  onClose: () => void;
};

export function UserModal({ uuid, onClose, show }: UserModalProps) {
  const { data: user, isFetching } = useGetUserInfoQuery(uuid ?? skipToken);

  const [updateEstimateStatus] = useUpdateEstimateStatusMutation();

  const [getEvidenceEntrySignedUrl] = useLazyGetEvidenceEntrySignedUrlQuery();

  const { data: sqidAlphabetPayload } = useGetSqidAlphabetQuery();

  const sqidsClient = useMemo(
    () =>
      sqidAlphabetPayload
        ? new Sqids({ alphabet: sqidAlphabetPayload.sqidAlphabet })
        : null,
    [sqidAlphabetPayload]
  );

  async function downloadEvidence(
    userUuid: string,
    feature: EvidenceFeatureEnum,
    evidenceUuid: string
  ) {
    const res = await getEvidenceEntrySignedUrl({
      userUuid,
      feature,
      evidenceUuid,
    });

    if ("data" in res && res.data) {
      window.open(res.data.signedUrl, "_blank")?.focus();
    } else {
      // eslint-disable-next-line no-console
      console.error(res.error);
    }
  }

  return (
    <Modal size="xl" show={show} onHide={onClose} id="user-info-modal">
      <Modal.Body>
        {user && !isFetching ? (
          <div>
            {/* if only there were some kind of Modal Header component... Oh well, too late to check now. */}
            <div id="modal-header">
              <div>
                <h2>{`${user.firstname} ${user.lastname}${
                  user.userAccountRefs.some(
                    (r) => r.account.accountType === "business"
                  )
                    ? ` (${user.userAccountRefs
                        .filter((r) => r.account.accountType === "business")
                        .map((r) => r.account.businessDetails?.companyName)
                        .join(", ")})`
                    : ""
                }`}</h2>
                <h3>{`User ${user.uuid}`}</h3>
              </div>
              <button
                type="button"
                className="btn-nostyle"
                id="close-btn"
                onClick={onClose}
              >
                <img
                  alt="close button"
                  src="/images/close.svg"
                  width={20}
                  height={20}
                />
              </button>
            </div>
            <Accordion alwaysOpen defaultActiveKey={["userinfo"]}>
              <Accordion.Item eventKey="userinfo">
                <Accordion.Header>
                  <h4>User Info</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <table>
                    <tbody>
                      <tr>
                        <td>Email</td>
                        <td>{user.email}</td>
                      </tr>
                      <tr>
                        <td>
                          Cognito ID (
                          {(() => {
                            if (user.cognitoId.startsWith("Google"))
                              return "Google";
                            if (user.cognitoId.startsWith("Facebook"))
                              return "Facebook";
                            return "Email/password";
                          })()}
                          )
                        </td>
                        <td>{user.cognitoId}</td>
                      </tr>
                      <tr>
                        <td>Registration date (UTC)</td>
                        <td>{new Date(user.createdAt).toISOString()}</td>
                      </tr>
                      <tr>
                        <td>Default currency</td>
                        <td>{user.defaultCurrency}</td>
                      </tr>
                      <tr>
                        <td>Medusa customer ID</td>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_MEDUSA_URL}/console/a/customers/${user.medusaCustomerId}`}
                          >
                            {user.medusaCustomerId ?? "[none]"}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile number</td>
                        <td>{user.mobileNumber ?? "[none]"}</td>
                      </tr>
                      <tr>
                        <td>Zoho contact ID</td>
                        <td>
                          {user.crmData?.zohoContactId ? (
                            <a
                              href={`${process.env.REACT_APP_ZOHO_CRM_URL}/tab/Contacts/${user.crmData.zohoContactId}`}
                            >
                              {user.crmData.zohoContactId}
                            </a>
                          ) : (
                            "[none]"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="logins">
                <Accordion.Header>
                  <h4>Logins ({user.userLoginAudits.length})</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {user.userLoginAudits && user.userLoginAudits.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>IP</th>
                          <th>Identity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...user.userLoginAudits]
                          .sort(
                            (a, b) =>
                              new Date(b.loginAt).getTime() -
                              new Date(a.loginAt).getTime()
                          )
                          .map((a) => (
                            <tr>
                              <td>
                                <FormattedDate value={new Date(a.loginAt)} />
                              </td>
                              <td>{a.loginIp}</td>
                              <td>{a.loginWith}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    "(none)"
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <h2 className="my-3">Accounts</h2>
            <Tabs>
              {user.userAccountRefs.map((ref) => {
                const { account } = ref;

                const impactPageUrl = sqidsClient
                  ? `${
                      process.env.REACT_APP_IMPACT_PAGE_URL
                    }/${sqidsClient.encode([account.id])}/some-slug`
                  : null;

                return (
                  <Tab
                    key={account.uuid}
                    tabClassName="text-black font-18"
                    eventKey={account.uuid}
                    title={
                      ref.account.accountType === "business"
                        ? `${ref.account.businessDetails.companyName} (business)`
                        : `${user.firstname} ${user.lastname} (personal)`
                    }
                    className="p-2"
                  >
                    <Accordion
                      alwaysOpen
                      defaultActiveKey={["accountInfo", "intents"]}
                    >
                      <Accordion.Item eventKey="accountInfo">
                        <Accordion.Header>
                          <h4>Account details</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <table>
                            <tbody>
                              {account.accountType === "business" ? (
                                <>
                                  <tr>
                                    <td>Account type</td>
                                    <td>{account.accountType}</td>
                                  </tr>
                                  <tr>
                                    <td>Country of incorporation</td>
                                    <td>
                                      {
                                        account.businessDetails
                                          ?.countryOfIncorporation
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>UUID</td>
                                    <td>{account.uuid}</td>
                                  </tr>
                                  <tr>
                                    <td>Website</td>
                                    <td>
                                      {account.businessDetails?.websiteUrl ??
                                        "[none]"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Logo</td>
                                    <td>
                                      {account.businessDetails?.brandLogoUrl ??
                                        "[none]"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>VAT number</td>
                                    <td>
                                      {account.businessDetails?.taxNumber ??
                                        "[none]"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Annual turnover</td>
                                    <td>
                                      {account.businessDetails
                                        ?.annualTurnover ?? "[none]"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Number of employees</td>
                                    <td>
                                      {account.businessDetails?.numEmployees ??
                                        "[none]"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Address</td>
                                    <td>
                                      {account.address
                                        ? (
                                            [
                                              "name",
                                              "addressLine1",
                                              "addressLine2",
                                              "city",
                                              "state",
                                              "postCode",
                                              "country",
                                            ] as const
                                          )
                                            .filter((line) =>
                                              Boolean(account.address[line])
                                            )
                                            .map((name) => (
                                              <p key={name}>
                                                {account.address[name]}
                                              </p>
                                            ))
                                        : `[none]`}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td>Country</td>
                                  <td>{account.personalDetails.country}</td>
                                </tr>
                              )}
                              <tr>
                                <td>Stripe customer ID*</td>
                                <td>
                                  <a
                                    href={`https://dashboard.stripe.com/customers/${ref.stripeCustomerId}`}
                                  >
                                    {ref.stripeCustomerId ?? "[none]"}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Zoho account ID</td>
                                <td>
                                  {account.crmData?.zohoAccountId ? (
                                    <a
                                      href={`${process.env.REACT_APP_ZOHO_CRM_URL}/tab/Accounts/${account.crmData.zohoAccountId}`}
                                    >
                                      {account.crmData.zohoAccountId}
                                    </a>
                                  ) : (
                                    "[none]"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          *different for any other users on this account
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="intents">
                        <Accordion.Header>
                          <h4>
                            Impact intents ({account.impactIntents.length})
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          {account.impactIntents &&
                          account.impactIntents.length > 0
                            ? [...account.impactIntents]
                                .sort((a, b) =>
                                  a.createdAt < b.createdAt ? 1 : -1
                                )
                                .map((intent) => (
                                  <div
                                    key={intent.uuid}
                                    className="intent-info"
                                  >
                                    <h6>{intent.uuid}</h6>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>Product(s)</td>
                                          <td>
                                            {intent.customerProductConfigs
                                              .map((c) => c.product.name)
                                              .join(", ")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Interval</td>
                                          <td>{intent.interval}</td>
                                        </tr>
                                        {intent.interval !== "one_time" && (
                                          <tr>
                                            <td>Subscription status</td>
                                            <td>{intent.subscriptionStatus}</td>
                                          </tr>
                                        )}
                                        <tr>
                                          <td>Payment status</td>
                                          <td>{intent.intentStatus}</td>
                                        </tr>
                                        <tr>
                                          <td>Creation date (UTC)</td>
                                          <td>
                                            {new Date(
                                              intent.createdAt
                                            ).toISOString()}
                                          </td>
                                        </tr>
                                        {intent.customerProductConfigs.some(
                                          (c) =>
                                            c.product.identifier ===
                                            "business-ecommerce-recurring"
                                        ) && (
                                          <tr>
                                            <td>Connected store(s)</td>
                                            <td>
                                              {intent.customerProductConfigs
                                                .filter(
                                                  (c) =>
                                                    c.product.identifier ===
                                                    "business-ecommerce-recurring"
                                                )
                                                .map((c) =>
                                                  c.store
                                                    ? `${c.store.storeName} (${c.store.platform})`
                                                    : "none"
                                                )}
                                            </td>
                                          </tr>
                                        )}
                                        {intent.stripeSubscriptionId && (
                                          <tr>
                                            <td>Stripe subscription ID</td>
                                            <td>
                                              <a
                                                href={`https://dashboard.stripe.com/subscriptions/${intent.stripeSubscriptionId}`}
                                              >
                                                {intent.stripeSubscriptionId}
                                              </a>
                                            </td>
                                          </tr>
                                        )}
                                        <tr>
                                          <td>Stripe intent ID</td>
                                          <td>
                                            {intent.stripeSetupIntentId ? (
                                              <a
                                                href={`https://dashboard.stripe.com/setup_intents/${intent.stripeSetupIntentId}`}
                                              >
                                                {intent.stripeSetupIntentId}
                                              </a>
                                            ) : (
                                              <a
                                                href={`https://dashboard.stripe.com/payments/${intent.stripePaymentIntentId}`}
                                              >
                                                {intent.stripePaymentIntentId}
                                              </a>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Payment(s)</td>
                                          <td>
                                            {intent.payments
                                              ? [...intent.payments]
                                                  ?.sort(
                                                    (a, b) =>
                                                      new Date(
                                                        b.createdAt
                                                      ).getTime() -
                                                      new Date(
                                                        a.createdAt
                                                      ).getTime()
                                                  )
                                                  .map((p) => (
                                                    <Fragment key={p.uuid}>
                                                      <div>
                                                        {p.uuid} (
                                                        <FormattedDate
                                                          value={
                                                            new Date(
                                                              p.createdAt
                                                            )
                                                          }
                                                        />
                                                        )
                                                      </div>
                                                      <ul className="small-list">
                                                        <li>
                                                          Status: {p.status}
                                                        </li>
                                                        <li>
                                                          Amount:{" "}
                                                          {p.currency && (
                                                            <FormattedNumber
                                                              style="currency"
                                                              value={workingToDisplayUnits(
                                                                p.currency.code,
                                                                p.orderTotal
                                                              )}
                                                              currency={
                                                                p.currency?.code
                                                              }
                                                            />
                                                          )}
                                                        </li>
                                                        <li>
                                                          Date (UTC):{" "}
                                                          {new Date(
                                                            p.createdAt
                                                          ).toISOString()}
                                                        </li>
                                                        <li>
                                                          Stripe invoice ID:{" "}
                                                          <a
                                                            href={`https://dashboard.stripe.com/invoices/${p.stripeInvoiceId}`}
                                                          >
                                                            {p.stripeInvoiceId}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          Medusa order ID(s):{" "}
                                                          {p.paymentConfigReferences?.map(
                                                            (c) => (
                                                              <a
                                                                href={`${process.env.REACT_APP_MEDUSA_URL}/console/a/orders/${c.medusaOrderId}`}
                                                              >
                                                                {
                                                                  c.medusaOrderId
                                                                }
                                                              </a>
                                                            )
                                                          )}
                                                        </li>
                                                        {p.crmData
                                                          ?.zohoDealId ? (
                                                          <li>
                                                            Zoho Deal ID:{" "}
                                                            <a
                                                              href={`${process.env.REACT_APP_ZOHO_CRM_URL}/tab/Potentials/${p.crmData.zohoDealId}`}
                                                            >
                                                              {
                                                                p.crmData
                                                                  .zohoDealId
                                                              }
                                                            </a>
                                                          </li>
                                                        ) : null}
                                                      </ul>
                                                    </Fragment>
                                                  ))
                                              : null}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ))
                            : "(none)"}
                        </Accordion.Body>
                      </Accordion.Item>
                      {account.accountType === "business" && (
                        <Accordion.Item eventKey="estimates">
                          <Accordion.Header>
                            <h4>Estimates ({account.estimates.length})</h4>
                          </Accordion.Header>
                          <Accordion.Body>
                            {account.estimates && account.estimates.length > 0
                              ? [...account.estimates]
                                  .sort((a, b) =>
                                    a.effectiveDate < b.effectiveDate ? 1 : -1
                                  )
                                  .map((e) => (
                                    <div key={e.uuid}>
                                      <h6>{e.uuid}</h6>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>Industry benchmark</td>
                                            <td>
                                              <FormattedNumber
                                                value={e.co2eEstimateKg}
                                              />{" "}
                                              Kg CO₂e per month (
                                              <FormattedNumber
                                                style="percent"
                                                minimumSignificantDigits={2}
                                                value={e.scopeOneProportion}
                                              />{" "}
                                              S1,{" "}
                                              <FormattedNumber
                                                style="percent"
                                                minimumSignificantDigits={2}
                                                value={e.scopeTwoProportion}
                                              />{" "}
                                              S2,{" "}
                                              <FormattedNumber
                                                style="percent"
                                                minimumSignificantDigits={2}
                                                value={e.scopeThreeProportion}
                                              />{" "}
                                              S3)
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Effective date</td>
                                            <td>{e.effectiveDate}</td>
                                          </tr>
                                          <tr>
                                            <td>Status</td>
                                            <td>
                                              {
                                                calculatedFootprintCopy[
                                                  e.status
                                                ]
                                              }{" "}
                                              {e.status !== "estimated" ? (
                                                <button
                                                  type="button"
                                                  className="ms-2"
                                                  onClick={() =>
                                                    updateEstimateStatus({
                                                      userUuid: user.uuid,
                                                      estimateUuid: e.uuid,
                                                      estimateStatus: (
                                                        [
                                                          "declared",
                                                          "verified",
                                                        ] as const
                                                      ).filter(
                                                        (s) => s !== e.status
                                                      )[0],
                                                    })
                                                  }
                                                >
                                                  {e.status === "verified"
                                                    ? "Un-verify"
                                                    : "Verify"}
                                                </button>
                                              ) : null}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>User data</td>
                                            <td>
                                              <pre>
                                                {JSON.stringify(
                                                  e.userProvidedValues,
                                                  undefined,
                                                  2
                                                )}
                                              </pre>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Creation date</td>
                                            <td>
                                              {new Date(
                                                e.createdAt
                                              ).toISOString()}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Update date</td>
                                            <td>
                                              {new Date(
                                                e.updatedAt
                                              ).toISOString()}
                                            </td>
                                          </tr>
                                          {"calculatedFootprint" in e &&
                                          e.calculatedFootprint ? (
                                            <tr>
                                              <td>Calculated footprint</td>
                                              <td>
                                                <Accordion alwaysOpen>
                                                  <Accordion.Item
                                                    eventKey={`${e.uuid}-calculationInformation`}
                                                  >
                                                    <Accordion.Header>
                                                      Calculation Data
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                      <Stack>
                                                        {(
                                                          [
                                                            "scopeOne",
                                                            "scopeTwo",
                                                            "scopeThree",
                                                          ] as const
                                                        ).map((name) => (
                                                          <div>
                                                            <b>
                                                              {
                                                                calculatedFootprintCopy[
                                                                  name
                                                                ]
                                                              }
                                                              :
                                                            </b>{" "}
                                                            <FormattedNumber
                                                              value={
                                                                Number(
                                                                  e.calculatedFootprint![
                                                                    `${name}Co2eKg`
                                                                  ]
                                                                ) / 1000
                                                              }
                                                            />{" "}
                                                            tonnes
                                                          </div>
                                                        ))}
                                                        <div className="mt-2">
                                                          <b>Inventory year:</b>{" "}
                                                          {new Date(
                                                            e.calculatedFootprint.inventoryYear
                                                          ).getFullYear()}
                                                        </div>
                                                        <div className="mb-2">
                                                          <b>
                                                            Consolidation
                                                            approach:
                                                          </b>{" "}
                                                          {
                                                            calculatedFootprintCopy[
                                                              e
                                                                .calculatedFootprint
                                                                .consolidationApproach
                                                            ]
                                                          }
                                                        </div>
                                                        {(
                                                          [
                                                            "scopeOneCategories",
                                                            "scopeTwoCategories",
                                                            "scopeThreeCategories",
                                                          ] as const
                                                        ).map((name) => (
                                                          <div>
                                                            <b>
                                                              {
                                                                calculatedFootprintCopy[
                                                                  name
                                                                ]
                                                              }
                                                              :
                                                            </b>{" "}
                                                            {e.calculatedFootprint![
                                                              name
                                                            ].length > 0 ? (
                                                              <ul className="m-0">
                                                                {e.calculatedFootprint![
                                                                  name
                                                                ].map((e) => (
                                                                  <li>
                                                                    {
                                                                      calculatedFootprintCopy[
                                                                        e
                                                                      ]
                                                                    }
                                                                  </li>
                                                                ))}
                                                              </ul>
                                                            ) : (
                                                              "<none>"
                                                            )}
                                                          </div>
                                                        ))}
                                                      </Stack>
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item
                                                    eventKey={`${e.uuid}-evidence`}
                                                  >
                                                    <Accordion.Header>
                                                      Evidence
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                      {e.calculatedFootprint
                                                        .evidenceEntryRefs
                                                        .length > 0 ? (
                                                        <table>
                                                          <tr>
                                                            <th>File name</th>
                                                            <th>Status</th>
                                                            <th>Download</th>
                                                            <th>UUID</th>
                                                          </tr>
                                                          {e.calculatedFootprint.evidenceEntryRefs.map(
                                                            (r) => (
                                                              <tr key={r.uuid}>
                                                                <td>
                                                                  {
                                                                    r
                                                                      .evidenceEntry
                                                                      .fileName
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    r
                                                                      .evidenceEntry
                                                                      .status
                                                                  }
                                                                </td>
                                                                <td>
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      downloadEvidence(
                                                                        account.uuid,
                                                                        "footprint",
                                                                        r
                                                                          .evidenceEntry
                                                                          .uuid
                                                                      )
                                                                    }
                                                                  >
                                                                    Download
                                                                  </button>
                                                                </td>
                                                                <td>
                                                                  {
                                                                    r
                                                                      .evidenceEntry
                                                                      .uuid
                                                                  }
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </table>
                                                      ) : (
                                                        "<none>"
                                                      )}
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                </Accordion>
                                              </td>
                                            </tr>
                                          ) : null}
                                        </tbody>
                                      </table>
                                    </div>
                                  ))
                              : "(none)"}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      <Accordion.Item eventKey="impactPageConfig">
                        <Accordion.Header>
                          <h4>Impact page config</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          {impactPageUrl ? (
                            <div className="mb-3">
                              <h3>
                                <a
                                  href={impactPageUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {impactPageUrl}
                                </a>
                              </h3>
                              <p>
                                (the slug is not known in the admin console, but
                                you will be redirected to the correct slug upon
                                clicking the link)
                              </p>
                            </div>
                          ) : (
                            <Spinner />
                          )}
                          <table>
                            <tr>
                              <td>Logo</td>
                              <td>
                                {account.impactPageConfig.logo?.bucketPath ? (
                                  <a
                                    href={`${process.env.REACT_APP_USER_IMAGE_HOST}/${account.impactPageConfig.logo.bucketPath}`}
                                  >
                                    Logo
                                  </a>
                                ) : (
                                  "[none]"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Name</td>
                              <td>
                                {account.impactPageConfig.name || "[none]"}
                              </td>
                            </tr>
                            <tr>
                              <td>Bio</td>
                              <td>
                                {account.impactPageConfig.bio || "[none]"}
                              </td>
                            </tr>
                            <tr>
                              <td>Mission statement</td>
                              <td>
                                {account.impactPageConfig.missionStatement ||
                                  "[none]"}
                              </td>
                            </tr>
                            <tr>
                              <td>Private</td>
                              <td>
                                {account.impactPageConfig.private || "[none]"}
                              </td>
                            </tr>
                            <tr>
                              <td>Website</td>
                              <td>
                                {account.impactPageConfig.website || "[none]"}
                              </td>
                            </tr>
                            <tr>
                              <td>Location</td>
                              <td>
                                {account.impactPageConfig.location || "[none]"}
                              </td>
                            </tr>
                            <tr>
                              <td>Impact view mode</td>
                              <td>{account.impactPageConfig.viewMode}</td>
                            </tr>
                            <tr>
                              <td>Fields shown</td>
                              <td>
                                <ul>
                                  {(
                                    [
                                      "showLogo",
                                      "showName",
                                      "showBio",
                                      "showMemberSince",
                                      "showMissionStatement",
                                      "showWebsite",
                                      "showLocation",
                                      "showEquivalentCards",
                                      "showProjectsMap",
                                      "showCo2eImpacts",
                                      "showTreesImpacts",
                                      "showPlasticImpacts",
                                    ] as const
                                  ).map((field) => (
                                    <li key={field}>
                                      {field.replaceAll("show", "")}:{" "}
                                      {account.impactPageConfig[field]
                                        ? "Shown"
                                        : "Not shown"}
                                    </li>
                                  ))}
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>Excluded products</td>
                              <td>
                                {account.impactPageConfig.excludeProducts
                                  .length > 0 ? (
                                  <ul>
                                    {account.impactPageConfig.excludeProducts.map(
                                      (p) => (
                                        <li key={p}>{p}</li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  "[none]"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Last updated (UTC)</td>
                              <td>
                                {new Date(
                                  account.impactPageConfig.updatedAt
                                ).toISOString()}
                              </td>
                            </tr>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="vehicles">
                        <Accordion.Header>
                          <h4>Vehicles ({account.vehicles.length})</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          {account.vehicles && account.vehicles.length > 0 ? (
                            <Accordion>
                              {account.vehicles.map((v) => (
                                <Accordion.Item key={v.uuid} eventKey={v.uuid}>
                                  <Accordion.Header>
                                    {v.uuid}
                                    {v.registrationNumber
                                      ? ` (${v.registrationNumber})`
                                      : null}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>Registration number</td>
                                          <td>{v.registrationNumber}</td>
                                        </tr>
                                        <tr>
                                          <td>Country of registration</td>
                                          <td>{v.countryOfRegistration}</td>
                                        </tr>
                                        <tr>
                                          <td>Annual mileage</td>
                                          <td>
                                            {v.annualMileage}{" "}
                                            {v.annualMileageUnit}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Grammes CO₂e per kilometre</td>
                                          <td>{v.gCo2ePerKm}</td>
                                        </tr>
                                        <tr>
                                          <td>Status</td>
                                          <td>{v.status}</td>
                                        </tr>
                                        <tr>
                                          <td>Vehicle data</td>
                                          <td>
                                            <pre>
                                              {JSON.stringify(
                                                {
                                                  ...v.vehicleData,
                                                  note: undefined,
                                                },
                                                undefined,
                                                2
                                              )}
                                            </pre>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Mileage records</td>
                                          <td>
                                            {v.mileageRecords &&
                                            v.mileageRecords.length > 0
                                              ? v.mileageRecords.map((r) => (
                                                  <div key={r.uuid}>
                                                    {r.uuid}
                                                    <table>
                                                      <tbody>
                                                        <tr>
                                                          <td>Start date</td>
                                                          <td>
                                                            {
                                                              r.effectiveStartDate
                                                            }
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>End date</td>
                                                          <td>
                                                            {r.effectiveEndDate}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>Mileage</td>
                                                          <td>
                                                            {parseInt(
                                                              r.quantity as unknown as string,
                                                              10
                                                            )}{" "}
                                                            {r.units}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            Associated impact
                                                            intent
                                                          </td>
                                                          <td>
                                                            {
                                                              r.impactIntent
                                                                .uuid
                                                            }
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                ))
                                              : "(none)"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Creation date</td>
                                          <td>
                                            <FormattedDate
                                              value={new Date(v.createdAt)}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          ) : (
                            "(none)"
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        ) : (
          <div id="user-modal-spinner-box">
            <Spinner />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
